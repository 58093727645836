/* tslint:disable */
/* eslint-disable */
/**
 * Raphael Müßeler API
 * API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@raphael-muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ComponentsContactComponent
 */
export interface ComponentsContactComponent {
    /**
     * 
     * @type {number}
     * @memberof ComponentsContactComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponent
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponent
     */
    'emailDisclaimer'?: string;
    /**
     * 
     * @type {ComponentsContactComponentSocialMedia}
     * @memberof ComponentsContactComponent
     */
    'socialMedia'?: ComponentsContactComponentSocialMedia;
}
/**
 * 
 * @export
 * @interface ComponentsContactComponentSocialMedia
 */
export interface ComponentsContactComponentSocialMedia {
    /**
     * 
     * @type {number}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'instagram'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'youtube'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'spotify'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'github'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsContactComponentSocialMedia
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface ComponentsLinkComponent
 */
export interface ComponentsLinkComponent {
    /**
     * 
     * @type {number}
     * @memberof ComponentsLinkComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComponentsLinkComponent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsLinkComponent
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsLinkComponent
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface ComponentsTextboxComponent
 */
export interface ComponentsTextboxComponent {
    /**
     * 
     * @type {number}
     * @memberof ComponentsTextboxComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComponentsTextboxComponent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsTextboxComponent
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsTextboxComponent
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsTextboxComponent
     */
    'learnMoreURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentsTextboxComponent
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface ContactRequest
 */
export interface ContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedBy}
     * @memberof ContactRequest
     */
    'createdBy'?: ContactRequestCreatedBy;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequest
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedBy
 */
export interface ContactRequestCreatedBy {
    /**
     * 
     * @type {ContactRequestCreatedByData}
     * @memberof ContactRequestCreatedBy
     */
    'data'?: ContactRequestCreatedByData;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByData
 */
export interface ContactRequestCreatedByData {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestCreatedByData
     */
    'id'?: number;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributes}
     * @memberof ContactRequestCreatedByData
     */
    'attributes'?: ContactRequestCreatedByDataAttributes;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributes
 */
export interface ContactRequestCreatedByDataAttributes {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'resetPasswordToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'registrationToken'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRoles}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'roles'?: ContactRequestCreatedByDataAttributesRoles;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'preferedLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRoles
 */
export interface ContactRequestCreatedByDataAttributesRoles {
    /**
     * 
     * @type {Array<ContactRequestCreatedByDataAttributesRolesDataInner>}
     * @memberof ContactRequestCreatedByDataAttributesRoles
     */
    'data'?: Array<ContactRequestCreatedByDataAttributesRolesDataInner>;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInner
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInner {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributes}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInner
     */
    'attributes'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributes;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'description'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'users'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissions}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'permissions'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissions;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissions
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissions {
    /**
     * 
     * @type {Array<ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner>}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissions
     */
    'data'?: Array<ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner>;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInner
     */
    'attributes'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'action'?: string;
    /**
     * 
     * @type {any}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'actionParameters'?: any;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'subject'?: string;
    /**
     * 
     * @type {any}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'properties'?: any;
    /**
     * 
     * @type {any}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'conditions'?: any;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'role'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole {
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole
     */
    'data'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers {
    /**
     * 
     * @type {Array<ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner>}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers
     */
    'data'?: Array<ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner>;
}
/**
 * 
 * @export
 * @interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner
 */
export interface ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {object}
     * @memberof ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsersDataInner
     */
    'attributes'?: object;
}
/**
 * 
 * @export
 * @interface ContactRequestListResponse
 */
export interface ContactRequestListResponse {
    /**
     * 
     * @type {Array<ContactRequestListResponseDataItem>}
     * @memberof ContactRequestListResponse
     */
    'data'?: Array<ContactRequestListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof ContactRequestListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface ContactRequestListResponseDataItem
 */
export interface ContactRequestListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {ContactRequest}
     * @memberof ContactRequestListResponseDataItem
     */
    'attributes'?: ContactRequest;
}
/**
 * 
 * @export
 * @interface ContactRequestListResponseMeta
 */
export interface ContactRequestListResponseMeta {
    /**
     * 
     * @type {ContactRequestListResponseMetaPagination}
     * @memberof ContactRequestListResponseMeta
     */
    'pagination'?: ContactRequestListResponseMetaPagination;
}
/**
 * 
 * @export
 * @interface ContactRequestListResponseMetaPagination
 */
export interface ContactRequestListResponseMetaPagination {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestListResponseMetaPagination
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactRequestListResponseMetaPagination
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactRequestListResponseMetaPagination
     */
    'pageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactRequestListResponseMetaPagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ContactRequestRequest
 */
export interface ContactRequestRequest {
    /**
     * 
     * @type {ContactRequestRequestData}
     * @memberof ContactRequestRequest
     */
    'data': ContactRequestRequestData;
}
/**
 * 
 * @export
 * @interface ContactRequestRequestData
 */
export interface ContactRequestRequestData {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestRequestData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestRequestData
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestRequestData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestRequestData
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ContactRequestResponse
 */
export interface ContactRequestResponse {
    /**
     * 
     * @type {ContactRequestResponseDataObject}
     * @memberof ContactRequestResponse
     */
    'data'?: ContactRequestResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof ContactRequestResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface ContactRequestResponseDataObject
 */
export interface ContactRequestResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {ContactRequest}
     * @memberof ContactRequestResponseDataObject
     */
    'attributes'?: ContactRequest;
}
/**
 * 
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedBy}
     * @memberof EmailTemplate
     */
    'createdBy'?: ContactRequestCreatedBy;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof EmailTemplate
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {EmailTemplateLocalizations}
     * @memberof EmailTemplate
     */
    'localizations'?: EmailTemplateLocalizations;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface EmailTemplateListResponse
 */
export interface EmailTemplateListResponse {
    /**
     * 
     * @type {Array<EmailTemplateListResponseDataItem>}
     * @memberof EmailTemplateListResponse
     */
    'data'?: Array<EmailTemplateListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof EmailTemplateListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface EmailTemplateListResponseDataItem
 */
export interface EmailTemplateListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {EmailTemplate}
     * @memberof EmailTemplateListResponseDataItem
     */
    'attributes'?: EmailTemplate;
}
/**
 * 
 * @export
 * @interface EmailTemplateListResponseDataItemLocalized
 */
export interface EmailTemplateListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {EmailTemplate}
     * @memberof EmailTemplateListResponseDataItemLocalized
     */
    'attributes'?: EmailTemplate;
}
/**
 * 
 * @export
 * @interface EmailTemplateLocalizationListResponse
 */
export interface EmailTemplateLocalizationListResponse {
    /**
     * 
     * @type {Array<EmailTemplateListResponseDataItemLocalized>}
     * @memberof EmailTemplateLocalizationListResponse
     */
    'data'?: Array<EmailTemplateListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof EmailTemplateLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface EmailTemplateLocalizationRequest
 */
export interface EmailTemplateLocalizationRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateLocalizationRequest
     */
    'locale': string;
}
/**
 * 
 * @export
 * @interface EmailTemplateLocalizationResponse
 */
export interface EmailTemplateLocalizationResponse {
    /**
     * 
     * @type {EmailTemplateResponseDataObjectLocalized}
     * @memberof EmailTemplateLocalizationResponse
     */
    'data'?: EmailTemplateResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof EmailTemplateLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface EmailTemplateLocalizations
 */
export interface EmailTemplateLocalizations {
    /**
     * 
     * @type {Array<EmailTemplate>}
     * @memberof EmailTemplateLocalizations
     */
    'data'?: Array<EmailTemplate>;
}
/**
 * 
 * @export
 * @interface EmailTemplateRequest
 */
export interface EmailTemplateRequest {
    /**
     * 
     * @type {EmailTemplateRequestData}
     * @memberof EmailTemplateRequest
     */
    'data': EmailTemplateRequestData;
}
/**
 * 
 * @export
 * @interface EmailTemplateRequestData
 */
export interface EmailTemplateRequestData {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateRequestData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface EmailTemplateResponse
 */
export interface EmailTemplateResponse {
    /**
     * 
     * @type {EmailTemplateResponseDataObject}
     * @memberof EmailTemplateResponse
     */
    'data'?: EmailTemplateResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof EmailTemplateResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface EmailTemplateResponseDataObject
 */
export interface EmailTemplateResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {EmailTemplate}
     * @memberof EmailTemplateResponseDataObject
     */
    'attributes'?: EmailTemplate;
}
/**
 * 
 * @export
 * @interface EmailTemplateResponseDataObjectLocalized
 */
export interface EmailTemplateResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {EmailTemplate}
     * @memberof EmailTemplateResponseDataObjectLocalized
     */
    'attributes'?: EmailTemplate;
}
/**
 * @type ErrorData
 * @export
 */
export type ErrorData = Array<object> | object;

/**
 * 
 * @export
 * @interface ErrorError
 */
export interface ErrorError {
    /**
     * 
     * @type {number}
     * @memberof ErrorError
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorError
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorError
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ErrorError
     */
    'details'?: object;
}
/**
 * 
 * @export
 * @interface I18NLocale
 */
export interface I18NLocale {
    /**
     * 
     * @type {string}
     * @memberof I18NLocale
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof I18NLocale
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof I18NLocale
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof I18NLocale
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedBy}
     * @memberof I18NLocale
     */
    'createdBy'?: ContactRequestCreatedBy;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof I18NLocale
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface I18NLocaleResponse
 */
export interface I18NLocaleResponse {
    /**
     * 
     * @type {I18NLocaleResponseDataObject}
     * @memberof I18NLocaleResponse
     */
    'data'?: I18NLocaleResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof I18NLocaleResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface I18NLocaleResponseDataObject
 */
export interface I18NLocaleResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof I18NLocaleResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {I18NLocale}
     * @memberof I18NLocaleResponseDataObject
     */
    'attributes'?: I18NLocale;
}
/**
 * 
 * @export
 * @interface LandingPage
 */
export interface LandingPage {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof LandingPage
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {Array<ComponentsTextboxComponent>}
     * @memberof LandingPage
     */
    'aboutMeContainer': Array<ComponentsTextboxComponent>;
    /**
     * 
     * @type {string}
     * @memberof LandingPage
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPage
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPage
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof LandingPage
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof LandingPage
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {LandingPageLocalizations}
     * @memberof LandingPage
     */
    'localizations'?: LandingPageLocalizations;
    /**
     * 
     * @type {string}
     * @memberof LandingPage
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface LandingPageListResponse
 */
export interface LandingPageListResponse {
    /**
     * 
     * @type {Array<LandingPageListResponseDataItem>}
     * @memberof LandingPageListResponse
     */
    'data'?: Array<LandingPageListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof LandingPageListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface LandingPageListResponseDataItem
 */
export interface LandingPageListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof LandingPageListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {LandingPage}
     * @memberof LandingPageListResponseDataItem
     */
    'attributes'?: LandingPage;
}
/**
 * 
 * @export
 * @interface LandingPageListResponseDataItemLocalized
 */
export interface LandingPageListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof LandingPageListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {LandingPage}
     * @memberof LandingPageListResponseDataItemLocalized
     */
    'attributes'?: LandingPage;
}
/**
 * 
 * @export
 * @interface LandingPageLocalizationListResponse
 */
export interface LandingPageLocalizationListResponse {
    /**
     * 
     * @type {Array<LandingPageListResponseDataItemLocalized>}
     * @memberof LandingPageLocalizationListResponse
     */
    'data'?: Array<LandingPageListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof LandingPageLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface LandingPageLocalizationRequest
 */
export interface LandingPageLocalizationRequest {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof LandingPageLocalizationRequest
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {Array<ComponentsTextboxComponent>}
     * @memberof LandingPageLocalizationRequest
     */
    'aboutMeContainer': Array<ComponentsTextboxComponent>;
    /**
     * 
     * @type {string}
     * @memberof LandingPageLocalizationRequest
     */
    'locale': string;
}
/**
 * 
 * @export
 * @interface LandingPageLocalizationResponse
 */
export interface LandingPageLocalizationResponse {
    /**
     * 
     * @type {LandingPageResponseDataObjectLocalized}
     * @memberof LandingPageLocalizationResponse
     */
    'data'?: LandingPageResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof LandingPageLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface LandingPageLocalizations
 */
export interface LandingPageLocalizations {
    /**
     * 
     * @type {Array<LandingPage>}
     * @memberof LandingPageLocalizations
     */
    'data'?: Array<LandingPage>;
}
/**
 * 
 * @export
 * @interface LandingPageRequest
 */
export interface LandingPageRequest {
    /**
     * 
     * @type {LandingPageRequestData}
     * @memberof LandingPageRequest
     */
    'data': LandingPageRequestData;
}
/**
 * 
 * @export
 * @interface LandingPageRequestData
 */
export interface LandingPageRequestData {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof LandingPageRequestData
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {Array<ComponentsTextboxComponent>}
     * @memberof LandingPageRequestData
     */
    'aboutMeContainer': Array<ComponentsTextboxComponent>;
    /**
     * 
     * @type {string}
     * @memberof LandingPageRequestData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface LandingPageResponse
 */
export interface LandingPageResponse {
    /**
     * 
     * @type {LandingPageResponseDataObject}
     * @memberof LandingPageResponse
     */
    'data'?: LandingPageResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof LandingPageResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface LandingPageResponseDataObject
 */
export interface LandingPageResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof LandingPageResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {LandingPage}
     * @memberof LandingPageResponseDataObject
     */
    'attributes'?: LandingPage;
}
/**
 * 
 * @export
 * @interface LandingPageResponseDataObjectLocalized
 */
export interface LandingPageResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof LandingPageResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {LandingPage}
     * @memberof LandingPageResponseDataObjectLocalized
     */
    'attributes'?: LandingPage;
}
/**
 * 
 * @export
 * @interface MasterData
 */
export interface MasterData {
    /**
     * 
     * @type {NavbarNavbarComponent}
     * @memberof MasterData
     */
    'navbar': NavbarNavbarComponent;
    /**
     * 
     * @type {ComponentsContactComponent}
     * @memberof MasterData
     */
    'contact': ComponentsContactComponent;
    /**
     * 
     * @type {SectionsFooterComponent}
     * @memberof MasterData
     */
    'footer': SectionsFooterComponent;
    /**
     * 
     * @type {string}
     * @memberof MasterData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MasterData
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MasterData
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof MasterData
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof MasterData
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {MasterDataLocalizations}
     * @memberof MasterData
     */
    'localizations'?: MasterDataLocalizations;
    /**
     * 
     * @type {string}
     * @memberof MasterData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface MasterDataListResponse
 */
export interface MasterDataListResponse {
    /**
     * 
     * @type {Array<MasterDataListResponseDataItem>}
     * @memberof MasterDataListResponse
     */
    'data'?: Array<MasterDataListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof MasterDataListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface MasterDataListResponseDataItem
 */
export interface MasterDataListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof MasterDataListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {MasterData}
     * @memberof MasterDataListResponseDataItem
     */
    'attributes'?: MasterData;
}
/**
 * 
 * @export
 * @interface MasterDataListResponseDataItemLocalized
 */
export interface MasterDataListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof MasterDataListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {MasterData}
     * @memberof MasterDataListResponseDataItemLocalized
     */
    'attributes'?: MasterData;
}
/**
 * 
 * @export
 * @interface MasterDataLocalizationListResponse
 */
export interface MasterDataLocalizationListResponse {
    /**
     * 
     * @type {Array<MasterDataListResponseDataItemLocalized>}
     * @memberof MasterDataLocalizationListResponse
     */
    'data'?: Array<MasterDataListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof MasterDataLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface MasterDataLocalizationRequest
 */
export interface MasterDataLocalizationRequest {
    /**
     * 
     * @type {NavbarNavbarComponent}
     * @memberof MasterDataLocalizationRequest
     */
    'navbar': NavbarNavbarComponent;
    /**
     * 
     * @type {ComponentsContactComponent}
     * @memberof MasterDataLocalizationRequest
     */
    'contact': ComponentsContactComponent;
    /**
     * 
     * @type {SectionsFooterComponent}
     * @memberof MasterDataLocalizationRequest
     */
    'footer': SectionsFooterComponent;
    /**
     * 
     * @type {string}
     * @memberof MasterDataLocalizationRequest
     */
    'locale': string;
}
/**
 * 
 * @export
 * @interface MasterDataLocalizationResponse
 */
export interface MasterDataLocalizationResponse {
    /**
     * 
     * @type {MasterDataResponseDataObjectLocalized}
     * @memberof MasterDataLocalizationResponse
     */
    'data'?: MasterDataResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof MasterDataLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface MasterDataLocalizations
 */
export interface MasterDataLocalizations {
    /**
     * 
     * @type {Array<MasterData>}
     * @memberof MasterDataLocalizations
     */
    'data'?: Array<MasterData>;
}
/**
 * 
 * @export
 * @interface MasterDataRequest
 */
export interface MasterDataRequest {
    /**
     * 
     * @type {MasterDataRequestData}
     * @memberof MasterDataRequest
     */
    'data': MasterDataRequestData;
}
/**
 * 
 * @export
 * @interface MasterDataRequestData
 */
export interface MasterDataRequestData {
    /**
     * 
     * @type {NavbarNavbarComponent}
     * @memberof MasterDataRequestData
     */
    'navbar': NavbarNavbarComponent;
    /**
     * 
     * @type {ComponentsContactComponent}
     * @memberof MasterDataRequestData
     */
    'contact': ComponentsContactComponent;
    /**
     * 
     * @type {SectionsFooterComponent}
     * @memberof MasterDataRequestData
     */
    'footer': SectionsFooterComponent;
    /**
     * 
     * @type {string}
     * @memberof MasterDataRequestData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface MasterDataResponse
 */
export interface MasterDataResponse {
    /**
     * 
     * @type {MasterDataResponseDataObject}
     * @memberof MasterDataResponse
     */
    'data'?: MasterDataResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof MasterDataResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface MasterDataResponseDataObject
 */
export interface MasterDataResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof MasterDataResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {MasterData}
     * @memberof MasterDataResponseDataObject
     */
    'attributes'?: MasterData;
}
/**
 * 
 * @export
 * @interface MasterDataResponseDataObjectLocalized
 */
export interface MasterDataResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof MasterDataResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {MasterData}
     * @memberof MasterDataResponseDataObjectLocalized
     */
    'attributes'?: MasterData;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {ErrorData}
     * @memberof ModelError
     */
    'data'?: ErrorData | null;
    /**
     * 
     * @type {ErrorError}
     * @memberof ModelError
     */
    'error': ErrorError;
}
/**
 * 
 * @export
 * @interface NavbarNavbarComponent
 */
export interface NavbarNavbarComponent {
    /**
     * 
     * @type {number}
     * @memberof NavbarNavbarComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NavbarNavbarComponent
     */
    'title'?: string;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImage}
     * @memberof NavbarNavbarComponent
     */
    'logo'?: SectionsHeaderComponentBackgroundImage;
    /**
     * 
     * @type {Array<NavbarNavbarComponentItemsInner>}
     * @memberof NavbarNavbarComponent
     */
    'items'?: Array<NavbarNavbarComponentItemsInner>;
}
/**
 * 
 * @export
 * @interface NavbarNavbarComponentItemsInner
 */
export interface NavbarNavbarComponentItemsInner {
    /**
     * 
     * @type {number}
     * @memberof NavbarNavbarComponentItemsInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NavbarNavbarComponentItemsInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavbarNavbarComponentItemsInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavbarNavbarComponentItemsInner
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'shortText': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'creationDate': string;
    /**
     * 
     * @type {ProjectBannerImages}
     * @memberof Project
     */
    'bannerImages': ProjectBannerImages;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'showOnLandingPage': boolean;
    /**
     * 
     * @type {ProjectTags}
     * @memberof Project
     */
    'tags'?: ProjectTags;
    /**
     * 
     * @type {Array<ComponentsLinkComponent>}
     * @memberof Project
     */
    'links'?: Array<ComponentsLinkComponent>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof Project
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof Project
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ProjectLocalizations}
     * @memberof Project
     */
    'localizations'?: ProjectLocalizations;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface ProjectBannerImages
 */
export interface ProjectBannerImages {
    /**
     * 
     * @type {Array<SectionsHeaderComponentBackgroundImageData>}
     * @memberof ProjectBannerImages
     */
    'data'?: Array<SectionsHeaderComponentBackgroundImageData>;
}
/**
 * 
 * @export
 * @interface ProjectListResponse
 */
export interface ProjectListResponse {
    /**
     * 
     * @type {Array<ProjectListResponseDataItem>}
     * @memberof ProjectListResponse
     */
    'data'?: Array<ProjectListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof ProjectListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface ProjectListResponseDataItem
 */
export interface ProjectListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {Project}
     * @memberof ProjectListResponseDataItem
     */
    'attributes'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectListResponseDataItemLocalized
 */
export interface ProjectListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {Project}
     * @memberof ProjectListResponseDataItemLocalized
     */
    'attributes'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectLocalizationListResponse
 */
export interface ProjectLocalizationListResponse {
    /**
     * 
     * @type {Array<ProjectListResponseDataItemLocalized>}
     * @memberof ProjectLocalizationListResponse
     */
    'data'?: Array<ProjectListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof ProjectLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface ProjectLocalizationRequest
 */
export interface ProjectLocalizationRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectLocalizationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocalizationRequest
     */
    'shortText': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocalizationRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocalizationRequest
     */
    'creationDate': string;
    /**
     * 
     * @type {Array<ProjectLocalizationRequestBannerImagesInner>}
     * @memberof ProjectLocalizationRequest
     */
    'bannerImages': Array<ProjectLocalizationRequestBannerImagesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectLocalizationRequest
     */
    'showOnLandingPage': boolean;
    /**
     * 
     * @type {Array<ProjectLocalizationRequestBannerImagesInner>}
     * @memberof ProjectLocalizationRequest
     */
    'tags'?: Array<ProjectLocalizationRequestBannerImagesInner>;
    /**
     * 
     * @type {Array<ComponentsLinkComponent>}
     * @memberof ProjectLocalizationRequest
     */
    'links'?: Array<ComponentsLinkComponent>;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocalizationRequest
     */
    'locale': string;
}
/**
 * @type ProjectLocalizationRequestBannerImagesInner
 * @export
 */
export type ProjectLocalizationRequestBannerImagesInner = number | string;

/**
 * 
 * @export
 * @interface ProjectLocalizationResponse
 */
export interface ProjectLocalizationResponse {
    /**
     * 
     * @type {ProjectResponseDataObjectLocalized}
     * @memberof ProjectLocalizationResponse
     */
    'data'?: ProjectResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof ProjectLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface ProjectLocalizations
 */
export interface ProjectLocalizations {
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectLocalizations
     */
    'data'?: Array<Project>;
}
/**
 * 
 * @export
 * @interface ProjectPage
 */
export interface ProjectPage {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof ProjectPage
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {string}
     * @memberof ProjectPage
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPage
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPage
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ProjectPage
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ProjectPage
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ProjectPageLocalizations}
     * @memberof ProjectPage
     */
    'localizations'?: ProjectPageLocalizations;
    /**
     * 
     * @type {string}
     * @memberof ProjectPage
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface ProjectPageListResponse
 */
export interface ProjectPageListResponse {
    /**
     * 
     * @type {Array<ProjectPageListResponseDataItem>}
     * @memberof ProjectPageListResponse
     */
    'data'?: Array<ProjectPageListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof ProjectPageListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface ProjectPageListResponseDataItem
 */
export interface ProjectPageListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectPageListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectPage}
     * @memberof ProjectPageListResponseDataItem
     */
    'attributes'?: ProjectPage;
}
/**
 * 
 * @export
 * @interface ProjectPageListResponseDataItemLocalized
 */
export interface ProjectPageListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof ProjectPageListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectPage}
     * @memberof ProjectPageListResponseDataItemLocalized
     */
    'attributes'?: ProjectPage;
}
/**
 * 
 * @export
 * @interface ProjectPageLocalizationListResponse
 */
export interface ProjectPageLocalizationListResponse {
    /**
     * 
     * @type {Array<ProjectPageListResponseDataItemLocalized>}
     * @memberof ProjectPageLocalizationListResponse
     */
    'data'?: Array<ProjectPageListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof ProjectPageLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface ProjectPageLocalizationRequest
 */
export interface ProjectPageLocalizationRequest {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof ProjectPageLocalizationRequest
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {string}
     * @memberof ProjectPageLocalizationRequest
     */
    'locale': string;
}
/**
 * 
 * @export
 * @interface ProjectPageLocalizationResponse
 */
export interface ProjectPageLocalizationResponse {
    /**
     * 
     * @type {ProjectPageResponseDataObjectLocalized}
     * @memberof ProjectPageLocalizationResponse
     */
    'data'?: ProjectPageResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof ProjectPageLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface ProjectPageLocalizations
 */
export interface ProjectPageLocalizations {
    /**
     * 
     * @type {Array<ProjectPage>}
     * @memberof ProjectPageLocalizations
     */
    'data'?: Array<ProjectPage>;
}
/**
 * 
 * @export
 * @interface ProjectPageRequest
 */
export interface ProjectPageRequest {
    /**
     * 
     * @type {ProjectPageRequestData}
     * @memberof ProjectPageRequest
     */
    'data': ProjectPageRequestData;
}
/**
 * 
 * @export
 * @interface ProjectPageRequestData
 */
export interface ProjectPageRequestData {
    /**
     * 
     * @type {SectionsHeaderComponent}
     * @memberof ProjectPageRequestData
     */
    'header': SectionsHeaderComponent;
    /**
     * 
     * @type {string}
     * @memberof ProjectPageRequestData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface ProjectPageResponse
 */
export interface ProjectPageResponse {
    /**
     * 
     * @type {ProjectPageResponseDataObject}
     * @memberof ProjectPageResponse
     */
    'data'?: ProjectPageResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof ProjectPageResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface ProjectPageResponseDataObject
 */
export interface ProjectPageResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof ProjectPageResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectPage}
     * @memberof ProjectPageResponseDataObject
     */
    'attributes'?: ProjectPage;
}
/**
 * 
 * @export
 * @interface ProjectPageResponseDataObjectLocalized
 */
export interface ProjectPageResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof ProjectPageResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectPage}
     * @memberof ProjectPageResponseDataObjectLocalized
     */
    'attributes'?: ProjectPage;
}
/**
 * 
 * @export
 * @interface ProjectRequest
 */
export interface ProjectRequest {
    /**
     * 
     * @type {ProjectRequestData}
     * @memberof ProjectRequest
     */
    'data': ProjectRequestData;
}
/**
 * 
 * @export
 * @interface ProjectRequestData
 */
export interface ProjectRequestData {
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestData
     */
    'shortText': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestData
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestData
     */
    'creationDate': string;
    /**
     * 
     * @type {Array<ProjectLocalizationRequestBannerImagesInner>}
     * @memberof ProjectRequestData
     */
    'bannerImages': Array<ProjectLocalizationRequestBannerImagesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRequestData
     */
    'showOnLandingPage': boolean;
    /**
     * 
     * @type {Array<ProjectLocalizationRequestBannerImagesInner>}
     * @memberof ProjectRequestData
     */
    'tags'?: Array<ProjectLocalizationRequestBannerImagesInner>;
    /**
     * 
     * @type {Array<ComponentsLinkComponent>}
     * @memberof ProjectRequestData
     */
    'links'?: Array<ComponentsLinkComponent>;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestData
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {ProjectResponseDataObject}
     * @memberof ProjectResponse
     */
    'data'?: ProjectResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof ProjectResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface ProjectResponseDataObject
 */
export interface ProjectResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {Project}
     * @memberof ProjectResponseDataObject
     */
    'attributes'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectResponseDataObjectLocalized
 */
export interface ProjectResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {Project}
     * @memberof ProjectResponseDataObjectLocalized
     */
    'attributes'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectTags
 */
export interface ProjectTags {
    /**
     * 
     * @type {Array<ProjectTagsDataInner>}
     * @memberof ProjectTags
     */
    'data'?: Array<ProjectTagsDataInner>;
}
/**
 * 
 * @export
 * @interface ProjectTagsDataInner
 */
export interface ProjectTagsDataInner {
    /**
     * 
     * @type {number}
     * @memberof ProjectTagsDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectTagsDataInnerAttributes}
     * @memberof ProjectTagsDataInner
     */
    'attributes'?: ProjectTagsDataInnerAttributes;
}
/**
 * 
 * @export
 * @interface ProjectTagsDataInnerAttributes
 */
export interface ProjectTagsDataInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'color'?: ProjectTagsDataInnerAttributesColorEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ProjectTagsDataInnerAttributesLocalizations}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'localizations'?: ProjectTagsDataInnerAttributesLocalizations;
    /**
     * 
     * @type {string}
     * @memberof ProjectTagsDataInnerAttributes
     */
    'locale'?: string;
}

export const ProjectTagsDataInnerAttributesColorEnum = {
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark'
} as const;

export type ProjectTagsDataInnerAttributesColorEnum = typeof ProjectTagsDataInnerAttributesColorEnum[keyof typeof ProjectTagsDataInnerAttributesColorEnum];

/**
 * 
 * @export
 * @interface ProjectTagsDataInnerAttributesLocalizations
 */
export interface ProjectTagsDataInnerAttributesLocalizations {
    /**
     * 
     * @type {Array<any>}
     * @memberof ProjectTagsDataInnerAttributesLocalizations
     */
    'data'?: Array<any>;
}
/**
 * 
 * @export
 * @interface SectionsFooterComponent
 */
export interface SectionsFooterComponent {
    /**
     * 
     * @type {number}
     * @memberof SectionsFooterComponent
     */
    'id'?: number;
    /**
     * 
     * @type {Array<NavbarNavbarComponentItemsInner>}
     * @memberof SectionsFooterComponent
     */
    'links'?: Array<NavbarNavbarComponentItemsInner>;
    /**
     * 
     * @type {SectionsFooterComponentCopyright}
     * @memberof SectionsFooterComponent
     */
    'copyright'?: SectionsFooterComponentCopyright;
}
/**
 * 
 * @export
 * @interface SectionsFooterComponentCopyright
 */
export interface SectionsFooterComponentCopyright {
    /**
     * 
     * @type {number}
     * @memberof SectionsFooterComponentCopyright
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsFooterComponentCopyright
     */
    'creationYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsFooterComponentCopyright
     */
    'fontawesomeInfo'?: string;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponent
 */
export interface SectionsHeaderComponent {
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponent
     */
    'pretitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponent
     */
    'description'?: string;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImage}
     * @memberof SectionsHeaderComponent
     */
    'backgroundImage'?: SectionsHeaderComponentBackgroundImage;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponent
     */
    'subtitle'?: string;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImage
 */
export interface SectionsHeaderComponentBackgroundImage {
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageData}
     * @memberof SectionsHeaderComponentBackgroundImage
     */
    'data'?: SectionsHeaderComponentBackgroundImageData;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageData
 */
export interface SectionsHeaderComponentBackgroundImageData {
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageData
     */
    'id'?: number;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributes}
     * @memberof SectionsHeaderComponentBackgroundImageData
     */
    'attributes'?: SectionsHeaderComponentBackgroundImageDataAttributes;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributes
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributes {
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'alternativeText'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'height'?: number;
    /**
     * 
     * @type {any}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'formats'?: any;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'ext'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'mime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'previewUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'provider'?: string;
    /**
     * 
     * @type {any}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'provider_metadata'?: any;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'related'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributesFolder}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'folder'?: SectionsHeaderComponentBackgroundImageDataAttributesFolder;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'folderPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolder
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolder {
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributesFolderData}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolder
     */
    'data'?: SectionsHeaderComponentBackgroundImageDataAttributesFolderData;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolderData
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolderData {
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderData
     */
    'id'?: number;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderData
     */
    'attributes'?: SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes {
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'pathId'?: number;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'parent'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'children'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFiles}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'files'?: SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFiles;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'createdBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFiles
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFiles {
    /**
     * 
     * @type {Array<SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner>}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFiles
     */
    'data'?: Array<SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner>;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner {
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInner
     */
    'attributes'?: SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes;
}
/**
 * 
 * @export
 * @interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
 */
export interface SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'alternativeText'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'height'?: number;
    /**
     * 
     * @type {any}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'formats'?: any;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'ext'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'mime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'previewUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'provider'?: string;
    /**
     * 
     * @type {any}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'provider_metadata'?: any;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'related'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesUsers;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'folder'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'folderPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedBy}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'createdBy'?: ContactRequestCreatedBy;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof SectionsHeaderComponentBackgroundImageDataAttributesFolderDataAttributesFilesDataInnerAttributes
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'color': TagColorEnum;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {ContactRequestCreatedBy}
     * @memberof Tag
     */
    'createdBy'?: ContactRequestCreatedBy;
    /**
     * 
     * @type {ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole}
     * @memberof Tag
     */
    'updatedBy'?: ContactRequestCreatedByDataAttributesRolesDataInnerAttributesPermissionsDataInnerAttributesRole;
    /**
     * 
     * @type {TagLocalizations}
     * @memberof Tag
     */
    'localizations'?: TagLocalizations;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'locale'?: string;
}

export const TagColorEnum = {
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark'
} as const;

export type TagColorEnum = typeof TagColorEnum[keyof typeof TagColorEnum];

/**
 * 
 * @export
 * @interface TagListResponse
 */
export interface TagListResponse {
    /**
     * 
     * @type {Array<TagListResponseDataItem>}
     * @memberof TagListResponse
     */
    'data'?: Array<TagListResponseDataItem>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof TagListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface TagListResponseDataItem
 */
export interface TagListResponseDataItem {
    /**
     * 
     * @type {number}
     * @memberof TagListResponseDataItem
     */
    'id'?: number;
    /**
     * 
     * @type {Tag}
     * @memberof TagListResponseDataItem
     */
    'attributes'?: Tag;
}
/**
 * 
 * @export
 * @interface TagListResponseDataItemLocalized
 */
export interface TagListResponseDataItemLocalized {
    /**
     * 
     * @type {number}
     * @memberof TagListResponseDataItemLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {Tag}
     * @memberof TagListResponseDataItemLocalized
     */
    'attributes'?: Tag;
}
/**
 * 
 * @export
 * @interface TagLocalizationListResponse
 */
export interface TagLocalizationListResponse {
    /**
     * 
     * @type {Array<TagListResponseDataItemLocalized>}
     * @memberof TagLocalizationListResponse
     */
    'data'?: Array<TagListResponseDataItemLocalized>;
    /**
     * 
     * @type {ContactRequestListResponseMeta}
     * @memberof TagLocalizationListResponse
     */
    'meta'?: ContactRequestListResponseMeta;
}
/**
 * 
 * @export
 * @interface TagLocalizationRequest
 */
export interface TagLocalizationRequest {
    /**
     * 
     * @type {string}
     * @memberof TagLocalizationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagLocalizationRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TagLocalizationRequest
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof TagLocalizationRequest
     */
    'color': TagLocalizationRequestColorEnum;
    /**
     * 
     * @type {string}
     * @memberof TagLocalizationRequest
     */
    'locale': string;
}

export const TagLocalizationRequestColorEnum = {
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark'
} as const;

export type TagLocalizationRequestColorEnum = typeof TagLocalizationRequestColorEnum[keyof typeof TagLocalizationRequestColorEnum];

/**
 * 
 * @export
 * @interface TagLocalizationResponse
 */
export interface TagLocalizationResponse {
    /**
     * 
     * @type {TagResponseDataObjectLocalized}
     * @memberof TagLocalizationResponse
     */
    'data'?: TagResponseDataObjectLocalized;
    /**
     * 
     * @type {object}
     * @memberof TagLocalizationResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface TagLocalizations
 */
export interface TagLocalizations {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagLocalizations
     */
    'data'?: Array<Tag>;
}
/**
 * 
 * @export
 * @interface TagRequest
 */
export interface TagRequest {
    /**
     * 
     * @type {TagRequestData}
     * @memberof TagRequest
     */
    'data': TagRequestData;
}
/**
 * 
 * @export
 * @interface TagRequestData
 */
export interface TagRequestData {
    /**
     * 
     * @type {string}
     * @memberof TagRequestData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagRequestData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TagRequestData
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof TagRequestData
     */
    'color': TagRequestDataColorEnum;
    /**
     * 
     * @type {string}
     * @memberof TagRequestData
     */
    'locale'?: string;
}

export const TagRequestDataColorEnum = {
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark'
} as const;

export type TagRequestDataColorEnum = typeof TagRequestDataColorEnum[keyof typeof TagRequestDataColorEnum];

/**
 * 
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * 
     * @type {TagResponseDataObject}
     * @memberof TagResponse
     */
    'data'?: TagResponseDataObject;
    /**
     * 
     * @type {object}
     * @memberof TagResponse
     */
    'meta'?: object;
}
/**
 * 
 * @export
 * @interface TagResponseDataObject
 */
export interface TagResponseDataObject {
    /**
     * 
     * @type {number}
     * @memberof TagResponseDataObject
     */
    'id'?: number;
    /**
     * 
     * @type {Tag}
     * @memberof TagResponseDataObject
     */
    'attributes'?: Tag;
}
/**
 * 
 * @export
 * @interface TagResponseDataObjectLocalized
 */
export interface TagResponseDataObjectLocalized {
    /**
     * 
     * @type {number}
     * @memberof TagResponseDataObjectLocalized
     */
    'id'?: number;
    /**
     * 
     * @type {Tag}
     * @memberof TagResponseDataObjectLocalized
     */
    'attributes'?: Tag;
}

/**
 * ContactRequestApi - axios parameter creator
 * @export
 */
export const ContactRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactRequestsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContactRequestsId', 'id', id)
            const localVarPath = `/contact-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactRequests: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactRequestsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactRequestsId', 'id', id)
            const localVarPath = `/contact-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRequestRequest} contactRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactRequests: async (contactRequestRequest: ContactRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactRequestRequest' is not null or undefined
            assertParamExists('postContactRequests', 'contactRequestRequest', contactRequestRequest)
            const localVarPath = `/contact-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactRequestRequest} contactRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactRequestsId: async (id: number, contactRequestRequest: ContactRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putContactRequestsId', 'id', id)
            // verify required parameter 'contactRequestRequest' is not null or undefined
            assertParamExists('putContactRequestsId', 'contactRequestRequest', contactRequestRequest)
            const localVarPath = `/contact-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactRequestApi - functional programming interface
 * @export
 */
export const ContactRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactRequestsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactRequestsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactRequests(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRequestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactRequests(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactRequestsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactRequestsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRequestRequest} contactRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContactRequests(contactRequestRequest: ContactRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContactRequests(contactRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactRequestRequest} contactRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContactRequestsId(id: number, contactRequestRequest: ContactRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContactRequestsId(id, contactRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactRequestApi - factory interface
 * @export
 */
export const ContactRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactRequestApiDeleteContactRequestsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactRequestsId(requestParameters: ContactRequestApiDeleteContactRequestsIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteContactRequestsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequestApiGetContactRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactRequests(requestParameters: ContactRequestApiGetContactRequestsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContactRequestListResponse> {
            return localVarFp.getContactRequests(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequestApiGetContactRequestsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactRequestsId(requestParameters: ContactRequestApiGetContactRequestsIdRequest, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse> {
            return localVarFp.getContactRequestsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequestApiPostContactRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactRequests(requestParameters: ContactRequestApiPostContactRequestsRequest, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse> {
            return localVarFp.postContactRequests(requestParameters.contactRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequestApiPutContactRequestsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactRequestsId(requestParameters: ContactRequestApiPutContactRequestsIdRequest, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse> {
            return localVarFp.putContactRequestsId(requestParameters.id, requestParameters.contactRequestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactRequestApi - interface
 * @export
 * @interface ContactRequestApi
 */
export interface ContactRequestApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApiInterface
     */
    deleteContactRequestsId(id: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApiInterface
     */
    getContactRequests(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<ContactRequestListResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApiInterface
     */
    getContactRequestsId(id: number, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse>;

    /**
     * 
     * @param {ContactRequestRequest} contactRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApiInterface
     */
    postContactRequests(contactRequestRequest: ContactRequestRequest, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse>;

    /**
     * 
     * @param {number} id 
     * @param {ContactRequestRequest} contactRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApiInterface
     */
    putContactRequestsId(id: number, contactRequestRequest: ContactRequestRequest, options?: AxiosRequestConfig): AxiosPromise<ContactRequestResponse>;

}

/**
 * Request parameters for deleteContactRequestsId operation in ContactRequestApi.
 * @export
 * @interface ContactRequestApiDeleteContactRequestsIdRequest
 */
export interface ContactRequestApiDeleteContactRequestsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestApiDeleteContactRequestsId
     */
    readonly id: number
}

/**
 * Request parameters for getContactRequests operation in ContactRequestApi.
 * @export
 * @interface ContactRequestApiGetContactRequestsRequest
 */
export interface ContactRequestApiGetContactRequestsRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof ContactRequestApiGetContactRequests
     */
    readonly locale?: string
}

/**
 * Request parameters for getContactRequestsId operation in ContactRequestApi.
 * @export
 * @interface ContactRequestApiGetContactRequestsIdRequest
 */
export interface ContactRequestApiGetContactRequestsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestApiGetContactRequestsId
     */
    readonly id: number
}

/**
 * Request parameters for postContactRequests operation in ContactRequestApi.
 * @export
 * @interface ContactRequestApiPostContactRequestsRequest
 */
export interface ContactRequestApiPostContactRequestsRequest {
    /**
     * 
     * @type {ContactRequestRequest}
     * @memberof ContactRequestApiPostContactRequests
     */
    readonly contactRequestRequest: ContactRequestRequest
}

/**
 * Request parameters for putContactRequestsId operation in ContactRequestApi.
 * @export
 * @interface ContactRequestApiPutContactRequestsIdRequest
 */
export interface ContactRequestApiPutContactRequestsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactRequestApiPutContactRequestsId
     */
    readonly id: number

    /**
     * 
     * @type {ContactRequestRequest}
     * @memberof ContactRequestApiPutContactRequestsId
     */
    readonly contactRequestRequest: ContactRequestRequest
}

/**
 * ContactRequestApi - object-oriented interface
 * @export
 * @class ContactRequestApi
 * @extends {BaseAPI}
 */
export class ContactRequestApi extends BaseAPI implements ContactRequestApiInterface {
    /**
     * 
     * @param {ContactRequestApiDeleteContactRequestsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApi
     */
    public deleteContactRequestsId(requestParameters: ContactRequestApiDeleteContactRequestsIdRequest, options?: AxiosRequestConfig) {
        return ContactRequestApiFp(this.configuration).deleteContactRequestsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequestApiGetContactRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApi
     */
    public getContactRequests(requestParameters: ContactRequestApiGetContactRequestsRequest = {}, options?: AxiosRequestConfig) {
        return ContactRequestApiFp(this.configuration).getContactRequests(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequestApiGetContactRequestsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApi
     */
    public getContactRequestsId(requestParameters: ContactRequestApiGetContactRequestsIdRequest, options?: AxiosRequestConfig) {
        return ContactRequestApiFp(this.configuration).getContactRequestsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequestApiPostContactRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApi
     */
    public postContactRequests(requestParameters: ContactRequestApiPostContactRequestsRequest, options?: AxiosRequestConfig) {
        return ContactRequestApiFp(this.configuration).postContactRequests(requestParameters.contactRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequestApiPutContactRequestsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestApi
     */
    public putContactRequestsId(requestParameters: ContactRequestApiPutContactRequestsIdRequest, options?: AxiosRequestConfig) {
        return ContactRequestApiFp(this.configuration).putContactRequestsId(requestParameters.id, requestParameters.contactRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmailTemplateApi - axios parameter creator
 * @export
 */
export const EmailTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailTemplatesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmailTemplatesId', 'id', id)
            const localVarPath = `/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplates: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplatesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailTemplatesId', 'id', id)
            const localVarPath = `/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailTemplateRequest} emailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailTemplates: async (emailTemplateRequest: EmailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailTemplateRequest' is not null or undefined
            assertParamExists('postEmailTemplates', 'emailTemplateRequest', emailTemplateRequest)
            const localVarPath = `/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmailTemplateLocalizationRequest} emailTemplateLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailTemplatesIdLocalizations: async (id: number, emailTemplateLocalizationRequest: EmailTemplateLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postEmailTemplatesIdLocalizations', 'id', id)
            // verify required parameter 'emailTemplateLocalizationRequest' is not null or undefined
            assertParamExists('postEmailTemplatesIdLocalizations', 'emailTemplateLocalizationRequest', emailTemplateLocalizationRequest)
            const localVarPath = `/email-templates/{id}/localizations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmailTemplateRequest} emailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmailTemplatesId: async (id: number, emailTemplateRequest: EmailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEmailTemplatesId', 'id', id)
            // verify required parameter 'emailTemplateRequest' is not null or undefined
            assertParamExists('putEmailTemplatesId', 'emailTemplateRequest', emailTemplateRequest)
            const localVarPath = `/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplateApi - functional programming interface
 * @export
 */
export const EmailTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailTemplatesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailTemplatesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplates(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplates(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplatesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplatesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailTemplateRequest} emailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailTemplates(emailTemplateRequest: EmailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailTemplates(emailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmailTemplateLocalizationRequest} emailTemplateLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailTemplatesIdLocalizations(id: number, emailTemplateLocalizationRequest: EmailTemplateLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailTemplatesIdLocalizations(id, emailTemplateLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmailTemplateRequest} emailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEmailTemplatesId(id: number, emailTemplateRequest: EmailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEmailTemplatesId(id, emailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplateApi - factory interface
 * @export
 */
export const EmailTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {EmailTemplateApiDeleteEmailTemplatesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailTemplatesId(requestParameters: EmailTemplateApiDeleteEmailTemplatesIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteEmailTemplatesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateApiGetEmailTemplatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplates(requestParameters: EmailTemplateApiGetEmailTemplatesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateListResponse> {
            return localVarFp.getEmailTemplates(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateApiGetEmailTemplatesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplatesId(requestParameters: EmailTemplateApiGetEmailTemplatesIdRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.getEmailTemplatesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateApiPostEmailTemplatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailTemplates(requestParameters: EmailTemplateApiPostEmailTemplatesRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.postEmailTemplates(requestParameters.emailTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailTemplatesIdLocalizations(requestParameters: EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateLocalizationResponse> {
            return localVarFp.postEmailTemplatesIdLocalizations(requestParameters.id, requestParameters.emailTemplateLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateApiPutEmailTemplatesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmailTemplatesId(requestParameters: EmailTemplateApiPutEmailTemplatesIdRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.putEmailTemplatesId(requestParameters.id, requestParameters.emailTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplateApi - interface
 * @export
 * @interface EmailTemplateApi
 */
export interface EmailTemplateApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    deleteEmailTemplatesId(id: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    getEmailTemplates(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateListResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    getEmailTemplatesId(id: number, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse>;

    /**
     * 
     * @param {EmailTemplateRequest} emailTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    postEmailTemplates(emailTemplateRequest: EmailTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse>;

    /**
     * 
     * @param {number} id 
     * @param {EmailTemplateLocalizationRequest} emailTemplateLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    postEmailTemplatesIdLocalizations(id: number, emailTemplateLocalizationRequest: EmailTemplateLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateLocalizationResponse>;

    /**
     * 
     * @param {number} id 
     * @param {EmailTemplateRequest} emailTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApiInterface
     */
    putEmailTemplatesId(id: number, emailTemplateRequest: EmailTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse>;

}

/**
 * Request parameters for deleteEmailTemplatesId operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiDeleteEmailTemplatesIdRequest
 */
export interface EmailTemplateApiDeleteEmailTemplatesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateApiDeleteEmailTemplatesId
     */
    readonly id: number
}

/**
 * Request parameters for getEmailTemplates operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiGetEmailTemplatesRequest
 */
export interface EmailTemplateApiGetEmailTemplatesRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof EmailTemplateApiGetEmailTemplates
     */
    readonly locale?: string
}

/**
 * Request parameters for getEmailTemplatesId operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiGetEmailTemplatesIdRequest
 */
export interface EmailTemplateApiGetEmailTemplatesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateApiGetEmailTemplatesId
     */
    readonly id: number
}

/**
 * Request parameters for postEmailTemplates operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiPostEmailTemplatesRequest
 */
export interface EmailTemplateApiPostEmailTemplatesRequest {
    /**
     * 
     * @type {EmailTemplateRequest}
     * @memberof EmailTemplateApiPostEmailTemplates
     */
    readonly emailTemplateRequest: EmailTemplateRequest
}

/**
 * Request parameters for postEmailTemplatesIdLocalizations operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest
 */
export interface EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateApiPostEmailTemplatesIdLocalizations
     */
    readonly id: number

    /**
     * 
     * @type {EmailTemplateLocalizationRequest}
     * @memberof EmailTemplateApiPostEmailTemplatesIdLocalizations
     */
    readonly emailTemplateLocalizationRequest: EmailTemplateLocalizationRequest
}

/**
 * Request parameters for putEmailTemplatesId operation in EmailTemplateApi.
 * @export
 * @interface EmailTemplateApiPutEmailTemplatesIdRequest
 */
export interface EmailTemplateApiPutEmailTemplatesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateApiPutEmailTemplatesId
     */
    readonly id: number

    /**
     * 
     * @type {EmailTemplateRequest}
     * @memberof EmailTemplateApiPutEmailTemplatesId
     */
    readonly emailTemplateRequest: EmailTemplateRequest
}

/**
 * EmailTemplateApi - object-oriented interface
 * @export
 * @class EmailTemplateApi
 * @extends {BaseAPI}
 */
export class EmailTemplateApi extends BaseAPI implements EmailTemplateApiInterface {
    /**
     * 
     * @param {EmailTemplateApiDeleteEmailTemplatesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public deleteEmailTemplatesId(requestParameters: EmailTemplateApiDeleteEmailTemplatesIdRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).deleteEmailTemplatesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateApiGetEmailTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public getEmailTemplates(requestParameters: EmailTemplateApiGetEmailTemplatesRequest = {}, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).getEmailTemplates(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateApiGetEmailTemplatesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public getEmailTemplatesId(requestParameters: EmailTemplateApiGetEmailTemplatesIdRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).getEmailTemplatesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateApiPostEmailTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public postEmailTemplates(requestParameters: EmailTemplateApiPostEmailTemplatesRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).postEmailTemplates(requestParameters.emailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public postEmailTemplatesIdLocalizations(requestParameters: EmailTemplateApiPostEmailTemplatesIdLocalizationsRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).postEmailTemplatesIdLocalizations(requestParameters.id, requestParameters.emailTemplateLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateApiPutEmailTemplatesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public putEmailTemplatesId(requestParameters: EmailTemplateApiPutEmailTemplatesIdRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).putEmailTemplatesId(requestParameters.id, requestParameters.emailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * I18nLocaleApi - axios parameter creator
 * @export
 */
export const I18nLocaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nLocales: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/i18n/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * I18nLocaleApi - functional programming interface
 * @export
 */
export const I18nLocaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = I18nLocaleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nLocales(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<I18NLocale>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nLocales(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * I18nLocaleApi - factory interface
 * @export
 */
export const I18nLocaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = I18nLocaleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nLocales(options?: AxiosRequestConfig): AxiosPromise<Array<I18NLocale>> {
            return localVarFp.getI18nLocales(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * I18nLocaleApi - interface
 * @export
 * @interface I18nLocaleApi
 */
export interface I18nLocaleApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nLocaleApiInterface
     */
    getI18nLocales(options?: AxiosRequestConfig): AxiosPromise<Array<I18NLocale>>;

}

/**
 * I18nLocaleApi - object-oriented interface
 * @export
 * @class I18nLocaleApi
 * @extends {BaseAPI}
 */
export class I18nLocaleApi extends BaseAPI implements I18nLocaleApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nLocaleApi
     */
    public getI18nLocales(options?: AxiosRequestConfig) {
        return I18nLocaleApiFp(this.configuration).getI18nLocales(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LandingPageApi - axios parameter creator
 * @export
 */
export const LandingPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLandingPage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/landing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPage: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/landing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LandingPageLocalizationRequest} landingPageLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandingPageLocalizations: async (landingPageLocalizationRequest: LandingPageLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landingPageLocalizationRequest' is not null or undefined
            assertParamExists('postLandingPageLocalizations', 'landingPageLocalizationRequest', landingPageLocalizationRequest)
            const localVarPath = `/landing-page/localizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(landingPageLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LandingPageRequest} landingPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLandingPage: async (landingPageRequest: LandingPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landingPageRequest' is not null or undefined
            assertParamExists('putLandingPage', 'landingPageRequest', landingPageRequest)
            const localVarPath = `/landing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(landingPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LandingPageApi - functional programming interface
 * @export
 */
export const LandingPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LandingPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLandingPage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLandingPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandingPage(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandingPage(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LandingPageLocalizationRequest} landingPageLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLandingPageLocalizations(landingPageLocalizationRequest: LandingPageLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingPageLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLandingPageLocalizations(landingPageLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LandingPageRequest} landingPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLandingPage(landingPageRequest: LandingPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLandingPage(landingPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LandingPageApi - factory interface
 * @export
 */
export const LandingPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LandingPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLandingPage(options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteLandingPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LandingPageApiGetLandingPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPage(requestParameters: LandingPageApiGetLandingPageRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LandingPageResponse> {
            return localVarFp.getLandingPage(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LandingPageApiPostLandingPageLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandingPageLocalizations(requestParameters: LandingPageApiPostLandingPageLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<LandingPageLocalizationResponse> {
            return localVarFp.postLandingPageLocalizations(requestParameters.landingPageLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LandingPageApiPutLandingPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLandingPage(requestParameters: LandingPageApiPutLandingPageRequest, options?: AxiosRequestConfig): AxiosPromise<LandingPageResponse> {
            return localVarFp.putLandingPage(requestParameters.landingPageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandingPageApi - interface
 * @export
 * @interface LandingPageApi
 */
export interface LandingPageApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApiInterface
     */
    deleteLandingPage(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApiInterface
     */
    getLandingPage(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<LandingPageResponse>;

    /**
     * 
     * @param {LandingPageLocalizationRequest} landingPageLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApiInterface
     */
    postLandingPageLocalizations(landingPageLocalizationRequest: LandingPageLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<LandingPageLocalizationResponse>;

    /**
     * 
     * @param {LandingPageRequest} landingPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApiInterface
     */
    putLandingPage(landingPageRequest: LandingPageRequest, options?: AxiosRequestConfig): AxiosPromise<LandingPageResponse>;

}

/**
 * Request parameters for getLandingPage operation in LandingPageApi.
 * @export
 * @interface LandingPageApiGetLandingPageRequest
 */
export interface LandingPageApiGetLandingPageRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof LandingPageApiGetLandingPage
     */
    readonly locale?: string
}

/**
 * Request parameters for postLandingPageLocalizations operation in LandingPageApi.
 * @export
 * @interface LandingPageApiPostLandingPageLocalizationsRequest
 */
export interface LandingPageApiPostLandingPageLocalizationsRequest {
    /**
     * 
     * @type {LandingPageLocalizationRequest}
     * @memberof LandingPageApiPostLandingPageLocalizations
     */
    readonly landingPageLocalizationRequest: LandingPageLocalizationRequest
}

/**
 * Request parameters for putLandingPage operation in LandingPageApi.
 * @export
 * @interface LandingPageApiPutLandingPageRequest
 */
export interface LandingPageApiPutLandingPageRequest {
    /**
     * 
     * @type {LandingPageRequest}
     * @memberof LandingPageApiPutLandingPage
     */
    readonly landingPageRequest: LandingPageRequest
}

/**
 * LandingPageApi - object-oriented interface
 * @export
 * @class LandingPageApi
 * @extends {BaseAPI}
 */
export class LandingPageApi extends BaseAPI implements LandingPageApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApi
     */
    public deleteLandingPage(options?: AxiosRequestConfig) {
        return LandingPageApiFp(this.configuration).deleteLandingPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LandingPageApiGetLandingPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApi
     */
    public getLandingPage(requestParameters: LandingPageApiGetLandingPageRequest = {}, options?: AxiosRequestConfig) {
        return LandingPageApiFp(this.configuration).getLandingPage(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LandingPageApiPostLandingPageLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApi
     */
    public postLandingPageLocalizations(requestParameters: LandingPageApiPostLandingPageLocalizationsRequest, options?: AxiosRequestConfig) {
        return LandingPageApiFp(this.configuration).postLandingPageLocalizations(requestParameters.landingPageLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LandingPageApiPutLandingPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageApi
     */
    public putLandingPage(requestParameters: LandingPageApiPutLandingPageRequest, options?: AxiosRequestConfig) {
        return LandingPageApiFp(this.configuration).putLandingPage(requestParameters.landingPageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MasterDataApi - axios parameter creator
 * @export
 */
export const MasterDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterData: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MasterDataLocalizationRequest} masterDataLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMasterDataLocalizations: async (masterDataLocalizationRequest: MasterDataLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterDataLocalizationRequest' is not null or undefined
            assertParamExists('postMasterDataLocalizations', 'masterDataLocalizationRequest', masterDataLocalizationRequest)
            const localVarPath = `/master-data/localizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(masterDataLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MasterDataRequest} masterDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMasterData: async (masterDataRequest: MasterDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterDataRequest' is not null or undefined
            assertParamExists('putMasterData', 'masterDataRequest', masterDataRequest)
            const localVarPath = `/master-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(masterDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterDataApi - functional programming interface
 * @export
 */
export const MasterDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMasterData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMasterData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterData(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterData(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MasterDataLocalizationRequest} masterDataLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMasterDataLocalizations(masterDataLocalizationRequest: MasterDataLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterDataLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMasterDataLocalizations(masterDataLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MasterDataRequest} masterDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMasterData(masterDataRequest: MasterDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMasterData(masterDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterDataApi - factory interface
 * @export
 */
export const MasterDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterDataApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterData(options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteMasterData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterDataApiGetMasterDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterData(requestParameters: MasterDataApiGetMasterDataRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MasterDataResponse> {
            return localVarFp.getMasterData(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterDataApiPostMasterDataLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMasterDataLocalizations(requestParameters: MasterDataApiPostMasterDataLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<MasterDataLocalizationResponse> {
            return localVarFp.postMasterDataLocalizations(requestParameters.masterDataLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterDataApiPutMasterDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMasterData(requestParameters: MasterDataApiPutMasterDataRequest, options?: AxiosRequestConfig): AxiosPromise<MasterDataResponse> {
            return localVarFp.putMasterData(requestParameters.masterDataRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterDataApi - interface
 * @export
 * @interface MasterDataApi
 */
export interface MasterDataApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    deleteMasterData(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    getMasterData(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<MasterDataResponse>;

    /**
     * 
     * @param {MasterDataLocalizationRequest} masterDataLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    postMasterDataLocalizations(masterDataLocalizationRequest: MasterDataLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<MasterDataLocalizationResponse>;

    /**
     * 
     * @param {MasterDataRequest} masterDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    putMasterData(masterDataRequest: MasterDataRequest, options?: AxiosRequestConfig): AxiosPromise<MasterDataResponse>;

}

/**
 * Request parameters for getMasterData operation in MasterDataApi.
 * @export
 * @interface MasterDataApiGetMasterDataRequest
 */
export interface MasterDataApiGetMasterDataRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof MasterDataApiGetMasterData
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof MasterDataApiGetMasterData
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof MasterDataApiGetMasterData
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof MasterDataApiGetMasterData
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof MasterDataApiGetMasterData
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof MasterDataApiGetMasterData
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof MasterDataApiGetMasterData
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof MasterDataApiGetMasterData
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof MasterDataApiGetMasterData
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof MasterDataApiGetMasterData
     */
    readonly locale?: string
}

/**
 * Request parameters for postMasterDataLocalizations operation in MasterDataApi.
 * @export
 * @interface MasterDataApiPostMasterDataLocalizationsRequest
 */
export interface MasterDataApiPostMasterDataLocalizationsRequest {
    /**
     * 
     * @type {MasterDataLocalizationRequest}
     * @memberof MasterDataApiPostMasterDataLocalizations
     */
    readonly masterDataLocalizationRequest: MasterDataLocalizationRequest
}

/**
 * Request parameters for putMasterData operation in MasterDataApi.
 * @export
 * @interface MasterDataApiPutMasterDataRequest
 */
export interface MasterDataApiPutMasterDataRequest {
    /**
     * 
     * @type {MasterDataRequest}
     * @memberof MasterDataApiPutMasterData
     */
    readonly masterDataRequest: MasterDataRequest
}

/**
 * MasterDataApi - object-oriented interface
 * @export
 * @class MasterDataApi
 * @extends {BaseAPI}
 */
export class MasterDataApi extends BaseAPI implements MasterDataApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public deleteMasterData(options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).deleteMasterData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterDataApiGetMasterDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public getMasterData(requestParameters: MasterDataApiGetMasterDataRequest = {}, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).getMasterData(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterDataApiPostMasterDataLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public postMasterDataLocalizations(requestParameters: MasterDataApiPostMasterDataLocalizationsRequest, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).postMasterDataLocalizations(requestParameters.masterDataLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterDataApiPutMasterDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public putMasterData(requestParameters: MasterDataApiPutMasterDataRequest, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).putMasterData(requestParameters.masterDataRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectsId', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectsId', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjects: async (projectRequest: ProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectRequest' is not null or undefined
            assertParamExists('postProjects', 'projectRequest', projectRequest)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectLocalizationRequest} projectLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectsIdLocalizations: async (id: number, projectLocalizationRequest: ProjectLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postProjectsIdLocalizations', 'id', id)
            // verify required parameter 'projectLocalizationRequest' is not null or undefined
            assertParamExists('postProjectsIdLocalizations', 'projectLocalizationRequest', projectLocalizationRequest)
            const localVarPath = `/projects/{id}/localizations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectsId: async (id: number, projectRequest: ProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putProjectsId', 'id', id)
            // verify required parameter 'projectRequest' is not null or undefined
            assertParamExists('putProjectsId', 'projectRequest', projectRequest)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjects(projectRequest: ProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjects(projectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectLocalizationRequest} projectLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectsIdLocalizations(id: number, projectLocalizationRequest: ProjectLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectsIdLocalizations(id, projectLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectsId(id: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectsId(id, projectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {ProjectApiDeleteProjectsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsId(requestParameters: ProjectApiDeleteProjectsIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteProjectsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectApiGetProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(requestParameters: ProjectApiGetProjectsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ProjectListResponse> {
            return localVarFp.getProjects(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectApiGetProjectsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsId(requestParameters: ProjectApiGetProjectsIdRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse> {
            return localVarFp.getProjectsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectApiPostProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjects(requestParameters: ProjectApiPostProjectsRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse> {
            return localVarFp.postProjects(requestParameters.projectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectApiPostProjectsIdLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectsIdLocalizations(requestParameters: ProjectApiPostProjectsIdLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectLocalizationResponse> {
            return localVarFp.postProjectsIdLocalizations(requestParameters.id, requestParameters.projectLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectApiPutProjectsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectsId(requestParameters: ProjectApiPutProjectsIdRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse> {
            return localVarFp.putProjectsId(requestParameters.id, requestParameters.projectRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - interface
 * @export
 * @interface ProjectApi
 */
export interface ProjectApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    deleteProjectsId(id: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    getProjects(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<ProjectListResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    getProjectsId(id: number, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse>;

    /**
     * 
     * @param {ProjectRequest} projectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    postProjects(projectRequest: ProjectRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse>;

    /**
     * 
     * @param {number} id 
     * @param {ProjectLocalizationRequest} projectLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    postProjectsIdLocalizations(id: number, projectLocalizationRequest: ProjectLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectLocalizationResponse>;

    /**
     * 
     * @param {number} id 
     * @param {ProjectRequest} projectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    putProjectsId(id: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectResponse>;

}

/**
 * Request parameters for deleteProjectsId operation in ProjectApi.
 * @export
 * @interface ProjectApiDeleteProjectsIdRequest
 */
export interface ProjectApiDeleteProjectsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectApiDeleteProjectsId
     */
    readonly id: number
}

/**
 * Request parameters for getProjects operation in ProjectApi.
 * @export
 * @interface ProjectApiGetProjectsRequest
 */
export interface ProjectApiGetProjectsRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof ProjectApiGetProjects
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof ProjectApiGetProjects
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof ProjectApiGetProjects
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof ProjectApiGetProjects
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof ProjectApiGetProjects
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof ProjectApiGetProjects
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof ProjectApiGetProjects
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof ProjectApiGetProjects
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof ProjectApiGetProjects
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof ProjectApiGetProjects
     */
    readonly locale?: string
}

/**
 * Request parameters for getProjectsId operation in ProjectApi.
 * @export
 * @interface ProjectApiGetProjectsIdRequest
 */
export interface ProjectApiGetProjectsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectApiGetProjectsId
     */
    readonly id: number
}

/**
 * Request parameters for postProjects operation in ProjectApi.
 * @export
 * @interface ProjectApiPostProjectsRequest
 */
export interface ProjectApiPostProjectsRequest {
    /**
     * 
     * @type {ProjectRequest}
     * @memberof ProjectApiPostProjects
     */
    readonly projectRequest: ProjectRequest
}

/**
 * Request parameters for postProjectsIdLocalizations operation in ProjectApi.
 * @export
 * @interface ProjectApiPostProjectsIdLocalizationsRequest
 */
export interface ProjectApiPostProjectsIdLocalizationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectApiPostProjectsIdLocalizations
     */
    readonly id: number

    /**
     * 
     * @type {ProjectLocalizationRequest}
     * @memberof ProjectApiPostProjectsIdLocalizations
     */
    readonly projectLocalizationRequest: ProjectLocalizationRequest
}

/**
 * Request parameters for putProjectsId operation in ProjectApi.
 * @export
 * @interface ProjectApiPutProjectsIdRequest
 */
export interface ProjectApiPutProjectsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectApiPutProjectsId
     */
    readonly id: number

    /**
     * 
     * @type {ProjectRequest}
     * @memberof ProjectApiPutProjectsId
     */
    readonly projectRequest: ProjectRequest
}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI implements ProjectApiInterface {
    /**
     * 
     * @param {ProjectApiDeleteProjectsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProjectsId(requestParameters: ProjectApiDeleteProjectsIdRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProjectsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectApiGetProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(requestParameters: ProjectApiGetProjectsRequest = {}, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjects(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectApiGetProjectsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectsId(requestParameters: ProjectApiGetProjectsIdRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectApiPostProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public postProjects(requestParameters: ProjectApiPostProjectsRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).postProjects(requestParameters.projectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectApiPostProjectsIdLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public postProjectsIdLocalizations(requestParameters: ProjectApiPostProjectsIdLocalizationsRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).postProjectsIdLocalizations(requestParameters.id, requestParameters.projectLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectApiPutProjectsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putProjectsId(requestParameters: ProjectApiPutProjectsIdRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).putProjectsId(requestParameters.id, requestParameters.projectRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectPageApi - axios parameter creator
 * @export
 */
export const ProjectPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectPage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPage: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectPageLocalizationRequest} projectPageLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectPageLocalizations: async (projectPageLocalizationRequest: ProjectPageLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectPageLocalizationRequest' is not null or undefined
            assertParamExists('postProjectPageLocalizations', 'projectPageLocalizationRequest', projectPageLocalizationRequest)
            const localVarPath = `/project-page/localizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPageLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectPageRequest} projectPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectPage: async (projectPageRequest: ProjectPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectPageRequest' is not null or undefined
            assertParamExists('putProjectPage', 'projectPageRequest', projectPageRequest)
            const localVarPath = `/project-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectPageApi - functional programming interface
 * @export
 */
export const ProjectPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectPage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectPage(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPage(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectPageLocalizationRequest} projectPageLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectPageLocalizations(projectPageLocalizationRequest: ProjectPageLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPageLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectPageLocalizations(projectPageLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectPageRequest} projectPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectPage(projectPageRequest: ProjectPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectPage(projectPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectPageApi - factory interface
 * @export
 */
export const ProjectPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectPage(options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteProjectPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectPageApiGetProjectPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPage(requestParameters: ProjectPageApiGetProjectPageRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ProjectPageResponse> {
            return localVarFp.getProjectPage(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectPageApiPostProjectPageLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectPageLocalizations(requestParameters: ProjectPageApiPostProjectPageLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectPageLocalizationResponse> {
            return localVarFp.postProjectPageLocalizations(requestParameters.projectPageLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectPageApiPutProjectPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectPage(requestParameters: ProjectPageApiPutProjectPageRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectPageResponse> {
            return localVarFp.putProjectPage(requestParameters.projectPageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectPageApi - interface
 * @export
 * @interface ProjectPageApi
 */
export interface ProjectPageApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApiInterface
     */
    deleteProjectPage(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApiInterface
     */
    getProjectPage(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<ProjectPageResponse>;

    /**
     * 
     * @param {ProjectPageLocalizationRequest} projectPageLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApiInterface
     */
    postProjectPageLocalizations(projectPageLocalizationRequest: ProjectPageLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectPageLocalizationResponse>;

    /**
     * 
     * @param {ProjectPageRequest} projectPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApiInterface
     */
    putProjectPage(projectPageRequest: ProjectPageRequest, options?: AxiosRequestConfig): AxiosPromise<ProjectPageResponse>;

}

/**
 * Request parameters for getProjectPage operation in ProjectPageApi.
 * @export
 * @interface ProjectPageApiGetProjectPageRequest
 */
export interface ProjectPageApiGetProjectPageRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof ProjectPageApiGetProjectPage
     */
    readonly locale?: string
}

/**
 * Request parameters for postProjectPageLocalizations operation in ProjectPageApi.
 * @export
 * @interface ProjectPageApiPostProjectPageLocalizationsRequest
 */
export interface ProjectPageApiPostProjectPageLocalizationsRequest {
    /**
     * 
     * @type {ProjectPageLocalizationRequest}
     * @memberof ProjectPageApiPostProjectPageLocalizations
     */
    readonly projectPageLocalizationRequest: ProjectPageLocalizationRequest
}

/**
 * Request parameters for putProjectPage operation in ProjectPageApi.
 * @export
 * @interface ProjectPageApiPutProjectPageRequest
 */
export interface ProjectPageApiPutProjectPageRequest {
    /**
     * 
     * @type {ProjectPageRequest}
     * @memberof ProjectPageApiPutProjectPage
     */
    readonly projectPageRequest: ProjectPageRequest
}

/**
 * ProjectPageApi - object-oriented interface
 * @export
 * @class ProjectPageApi
 * @extends {BaseAPI}
 */
export class ProjectPageApi extends BaseAPI implements ProjectPageApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApi
     */
    public deleteProjectPage(options?: AxiosRequestConfig) {
        return ProjectPageApiFp(this.configuration).deleteProjectPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectPageApiGetProjectPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApi
     */
    public getProjectPage(requestParameters: ProjectPageApiGetProjectPageRequest = {}, options?: AxiosRequestConfig) {
        return ProjectPageApiFp(this.configuration).getProjectPage(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectPageApiPostProjectPageLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApi
     */
    public postProjectPageLocalizations(requestParameters: ProjectPageApiPostProjectPageLocalizationsRequest, options?: AxiosRequestConfig) {
        return ProjectPageApiFp(this.configuration).postProjectPageLocalizations(requestParameters.projectPageLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectPageApiPutProjectPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectPageApi
     */
    public putProjectPage(requestParameters: ProjectPageApiPutProjectPageRequest, options?: AxiosRequestConfig) {
        return ProjectPageApiFp(this.configuration).putProjectPage(requestParameters.projectPageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTagsId', 'id', id)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (paginationWithCount !== undefined) {
                localVarQueryParameter['pagination[withCount]'] = paginationWithCount;
            }

            if (paginationPage !== undefined) {
                localVarQueryParameter['pagination[page]'] = paginationPage;
            }

            if (paginationPageSize !== undefined) {
                localVarQueryParameter['pagination[pageSize]'] = paginationPageSize;
            }

            if (paginationStart !== undefined) {
                localVarQueryParameter['pagination[start]'] = paginationStart;
            }

            if (paginationLimit !== undefined) {
                localVarQueryParameter['pagination[limit]'] = paginationLimit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagsId', 'id', id)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTags: async (tagRequest: TagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('postTags', 'tagRequest', tagRequest)
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TagLocalizationRequest} tagLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagsIdLocalizations: async (id: number, tagLocalizationRequest: TagLocalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTagsIdLocalizations', 'id', id)
            // verify required parameter 'tagLocalizationRequest' is not null or undefined
            assertParamExists('postTagsIdLocalizations', 'tagLocalizationRequest', tagLocalizationRequest)
            const localVarPath = `/tags/{id}/localizations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagLocalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagsId: async (id: number, tagRequest: TagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTagsId', 'id', id)
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('putTagsId', 'tagRequest', tagRequest)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
         * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
         * @param {number} [paginationPage] Page number (default: 0)
         * @param {number} [paginationPageSize] Page size (default: 25)
         * @param {number} [paginationStart] Offset value (default: 0)
         * @param {number} [paginationLimit] Number of entities to return (default: 25)
         * @param {string} [fields] Fields to return (ex: title,author)
         * @param {string} [populate] Relations to return
         * @param {object} [filters] Filters to apply
         * @param {string} [locale] Locale to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTags(tagRequest: TagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTags(tagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TagLocalizationRequest} tagLocalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTagsIdLocalizations(id: number, tagLocalizationRequest: TagLocalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagLocalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTagsIdLocalizations(id, tagLocalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTagsId(id: number, tagRequest: TagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTagsId(id, tagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @param {TagApiDeleteTagsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagsId(requestParameters: TagApiDeleteTagsIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteTagsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagApiGetTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(requestParameters: TagApiGetTagsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TagListResponse> {
            return localVarFp.getTags(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagApiGetTagsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsId(requestParameters: TagApiGetTagsIdRequest, options?: AxiosRequestConfig): AxiosPromise<TagResponse> {
            return localVarFp.getTagsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagApiPostTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTags(requestParameters: TagApiPostTagsRequest, options?: AxiosRequestConfig): AxiosPromise<TagResponse> {
            return localVarFp.postTags(requestParameters.tagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagApiPostTagsIdLocalizationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagsIdLocalizations(requestParameters: TagApiPostTagsIdLocalizationsRequest, options?: AxiosRequestConfig): AxiosPromise<TagLocalizationResponse> {
            return localVarFp.postTagsIdLocalizations(requestParameters.id, requestParameters.tagLocalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagApiPutTagsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagsId(requestParameters: TagApiPutTagsIdRequest, options?: AxiosRequestConfig): AxiosPromise<TagResponse> {
            return localVarFp.putTagsId(requestParameters.id, requestParameters.tagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - interface
 * @export
 * @interface TagApi
 */
export interface TagApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    deleteTagsId(id: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [sort] Sort by attributes ascending (asc) or descending (desc)
     * @param {boolean} [paginationWithCount] Return page/pageSize (default: true)
     * @param {number} [paginationPage] Page number (default: 0)
     * @param {number} [paginationPageSize] Page size (default: 25)
     * @param {number} [paginationStart] Offset value (default: 0)
     * @param {number} [paginationLimit] Number of entities to return (default: 25)
     * @param {string} [fields] Fields to return (ex: title,author)
     * @param {string} [populate] Relations to return
     * @param {object} [filters] Filters to apply
     * @param {string} [locale] Locale to apply
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    getTags(sort?: string, paginationWithCount?: boolean, paginationPage?: number, paginationPageSize?: number, paginationStart?: number, paginationLimit?: number, fields?: string, populate?: string, filters?: object, locale?: string, options?: AxiosRequestConfig): AxiosPromise<TagListResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    getTagsId(id: number, options?: AxiosRequestConfig): AxiosPromise<TagResponse>;

    /**
     * 
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    postTags(tagRequest: TagRequest, options?: AxiosRequestConfig): AxiosPromise<TagResponse>;

    /**
     * 
     * @param {number} id 
     * @param {TagLocalizationRequest} tagLocalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    postTagsIdLocalizations(id: number, tagLocalizationRequest: TagLocalizationRequest, options?: AxiosRequestConfig): AxiosPromise<TagLocalizationResponse>;

    /**
     * 
     * @param {number} id 
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApiInterface
     */
    putTagsId(id: number, tagRequest: TagRequest, options?: AxiosRequestConfig): AxiosPromise<TagResponse>;

}

/**
 * Request parameters for deleteTagsId operation in TagApi.
 * @export
 * @interface TagApiDeleteTagsIdRequest
 */
export interface TagApiDeleteTagsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TagApiDeleteTagsId
     */
    readonly id: number
}

/**
 * Request parameters for getTags operation in TagApi.
 * @export
 * @interface TagApiGetTagsRequest
 */
export interface TagApiGetTagsRequest {
    /**
     * Sort by attributes ascending (asc) or descending (desc)
     * @type {string}
     * @memberof TagApiGetTags
     */
    readonly sort?: string

    /**
     * Return page/pageSize (default: true)
     * @type {boolean}
     * @memberof TagApiGetTags
     */
    readonly paginationWithCount?: boolean

    /**
     * Page number (default: 0)
     * @type {number}
     * @memberof TagApiGetTags
     */
    readonly paginationPage?: number

    /**
     * Page size (default: 25)
     * @type {number}
     * @memberof TagApiGetTags
     */
    readonly paginationPageSize?: number

    /**
     * Offset value (default: 0)
     * @type {number}
     * @memberof TagApiGetTags
     */
    readonly paginationStart?: number

    /**
     * Number of entities to return (default: 25)
     * @type {number}
     * @memberof TagApiGetTags
     */
    readonly paginationLimit?: number

    /**
     * Fields to return (ex: title,author)
     * @type {string}
     * @memberof TagApiGetTags
     */
    readonly fields?: string

    /**
     * Relations to return
     * @type {string}
     * @memberof TagApiGetTags
     */
    readonly populate?: string

    /**
     * Filters to apply
     * @type {object}
     * @memberof TagApiGetTags
     */
    readonly filters?: object

    /**
     * Locale to apply
     * @type {string}
     * @memberof TagApiGetTags
     */
    readonly locale?: string
}

/**
 * Request parameters for getTagsId operation in TagApi.
 * @export
 * @interface TagApiGetTagsIdRequest
 */
export interface TagApiGetTagsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TagApiGetTagsId
     */
    readonly id: number
}

/**
 * Request parameters for postTags operation in TagApi.
 * @export
 * @interface TagApiPostTagsRequest
 */
export interface TagApiPostTagsRequest {
    /**
     * 
     * @type {TagRequest}
     * @memberof TagApiPostTags
     */
    readonly tagRequest: TagRequest
}

/**
 * Request parameters for postTagsIdLocalizations operation in TagApi.
 * @export
 * @interface TagApiPostTagsIdLocalizationsRequest
 */
export interface TagApiPostTagsIdLocalizationsRequest {
    /**
     * 
     * @type {number}
     * @memberof TagApiPostTagsIdLocalizations
     */
    readonly id: number

    /**
     * 
     * @type {TagLocalizationRequest}
     * @memberof TagApiPostTagsIdLocalizations
     */
    readonly tagLocalizationRequest: TagLocalizationRequest
}

/**
 * Request parameters for putTagsId operation in TagApi.
 * @export
 * @interface TagApiPutTagsIdRequest
 */
export interface TagApiPutTagsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TagApiPutTagsId
     */
    readonly id: number

    /**
     * 
     * @type {TagRequest}
     * @memberof TagApiPutTagsId
     */
    readonly tagRequest: TagRequest
}

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI implements TagApiInterface {
    /**
     * 
     * @param {TagApiDeleteTagsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public deleteTagsId(requestParameters: TagApiDeleteTagsIdRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).deleteTagsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiGetTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTags(requestParameters: TagApiGetTagsRequest = {}, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getTags(requestParameters.sort, requestParameters.paginationWithCount, requestParameters.paginationPage, requestParameters.paginationPageSize, requestParameters.paginationStart, requestParameters.paginationLimit, requestParameters.fields, requestParameters.populate, requestParameters.filters, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiGetTagsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagsId(requestParameters: TagApiGetTagsIdRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getTagsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiPostTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public postTags(requestParameters: TagApiPostTagsRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).postTags(requestParameters.tagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiPostTagsIdLocalizationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public postTagsIdLocalizations(requestParameters: TagApiPostTagsIdLocalizationsRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).postTagsIdLocalizations(requestParameters.id, requestParameters.tagLocalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiPutTagsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public putTagsId(requestParameters: TagApiPutTagsIdRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).putTagsId(requestParameters.id, requestParameters.tagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



